//import logo from "./logo.svg";
import React, { useState, useRef, useEffect } from "react";

import "./App.css";

function App() {
  return (
    <>
      <h1>This is a sample About-Us Page</h1>
      <a href="/search">Click to go back to search page!</a>
    </>
  );
}

export default App;
